import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Dialog from 'components/global/mui4/Dialog';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { FlatButton } from 'material-ui';
import Title from 'components/global/Title';

const AddSponsoredMinutesDialog = ({
  open,
  onSubmit,
  onRequestClose,
  intl,
}) => {
  const [hours, setHours] = useState('');
  const [counsellingType, setCounsellingType] = useState('');
  const [error, setError] = useState({});

  const handleCounsellingTypeChange = event => {
    setCounsellingType(event.target.value);
    setError(''); // Clear error when user interacts
  };

  const handleHoursChange = event => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      setHours(value);
      setError({}); // Clear error when user interacts
    }
  };

  const handleSubmit = () => {
    const errors = {};

    if (!hours) {
      errors.hours = intl.formatMessage({
        defaultMessage: 'Please enter an amount for Number of Additional Hours',
      });
    }

    if (!counsellingType) {
      errors.counsellingType = intl.formatMessage({
        defaultMessage: 'Please select a counselling type',
      });
    }

    if (Object.keys(errors).length > 0) {
      setError(errors);
      return;
    }

    onSubmit({ hours, counsellingType });
  };

  return (
    <Dialog open={open} onClose={onRequestClose}>
      <Title
        title={intl.formatMessage({
          defaultMessage: 'Add Additional Hours',
        })}
      />
      <b>
        {intl.formatMessage({
          defaultMessage:
            'Note: Additional hours currently only apply to clients who are part of legacy Inkblot companies, and will not appear for GS+ users',
        })}
      </b>
      <Select
        value={counsellingType}
        onChange={handleCounsellingTypeChange}
        displayEmpty
        fullWidth
        style={{ marginTop: 16 }}
      >
        <MenuItem value="" disabled>
          {intl.formatMessage({ defaultMessage: 'Counselling Type' })}
        </MenuItem>
        <MenuItem value="individual">
          {intl.formatMessage({ defaultMessage: 'Individual Counselling' })}
        </MenuItem>
        <MenuItem value="couples">
          {intl.formatMessage({ defaultMessage: 'Couples Counselling' })}
        </MenuItem>
      </Select>
      {error.counsellingType && (
        <div style={{ color: 'red', marginBottom: '16px' }}>
          {error.counsellingType}
        </div>
      )}
      <TextField
        name="hours"
        label={intl.formatMessage({
          defaultMessage: 'Number of Additional Hours',
        })}
        type="text"
        value={hours}
        onChange={handleHoursChange}
        fullWidth
        margin="normal"
      />
      {error.hours && (
        <div style={{ color: 'red', marginBottom: '16px' }}>{error.hours}</div>
      )}
      <div
        style={{
          marginTop: '12px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <FlatButton
          label={intl.formatMessage({ defaultMessage: 'Save' })}
          onClick={handleSubmit}
          className="flat-button-green"
        />
        <FlatButton
          label={intl.formatMessage({ defaultMessage: 'Cancel' })}
          onClick={onRequestClose}
          className="flat-button-red"
          style={{ marginLeft: 10 }}
        />
      </div>
    </Dialog>
  );
};

AddSponsoredMinutesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(AddSponsoredMinutesDialog);
